<div class="latest-news">
  <div class="img-div">
    <img [src]="newsItem.image || './assets/download.png'" alt="news" />
  </div>
  <div class="text">
    <h3>{{ newsItem.title }}</h3>
    <p>{{ newsItem.text }}</p>
    <span class="date">{{ newsItem.published }}, {{ newsItem.posted_by }}</span>
  </div>
</div>
