import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { News } from "../../../../../@domain/news/entities/news.interface";
import { ImageDirective } from '../../../../../@common/DOM/image.directive';

@Component({
    selector: 'app-news-item',
    templateUrl: './news-item.component.html',
    styleUrls: [ './news-item.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ ImageDirective ]
})
export class NewsItemComponent {
  @Input({required: true}) newsItem!: News;
}
