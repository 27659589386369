import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, of, shareReplay } from 'rxjs';
import { Logger } from 'src/app/@common/log/logger';
import { environment } from 'src/environments/environment';
import { Page } from '../../../@common/entities/page.interface';
import { News } from '../entities/news.interface';

@Injectable({
    providedIn: 'root',
})
export class NewsService {
    private http = inject(HttpClient);
    private logger = inject(Logger);

    getAllNews(limit: number, offset: number): Observable<Page<News>> {
        return this.http.get<Page<News>>(`${ environment.apiUrl }/news/latest/?limit=${ limit }&offset=${ offset }`)
            .pipe(
                shareReplay(1),
                catchError(e => {
                    this.logger.apiError('Get news failed', e);
                    return of({});
                })
            );
    }

}
